<template>
  <div class="liveFeedContainer">
    <cameraFeed v-for="cam in cameraUrls" v-bind:key="cam" :cameraUrl="cam" />
  </div>
</template>

<script>
//import ApiService from "@/common/api.service";
//import JWTService from "@/common/jwt.service";
//import { mapGetters } from "vuex";
//import KTPortlet from "@/views/partials/content/Portlet.vue";
//import Loader from "@/views/partials/content/Loader.vue";
import { SET_BREADCRUMB } from "@/store/breadcrumbs.module";
////import Swal from "sweetalert2";
//import TLib from "./lib";
//import WAChatItem from "./whatsapp.chatItem.vue";
//import WADefaultPic from "./whatsapp.defpic.vue";
import ApiService from "@/common/api.service";
import JWTService from "@/common/jwt.service";
//import ImageIcon from "./personIconImage";
//import MOMENT from "moment";
import cameraFeed from "./cameraFeed.vue";

export default {
  components: {
    cameraFeed,
    //KTPortlet,
    //Loader,
    //WAChatItem,
    //WADefaultPic,
    //personIconImage: ImageIcon,
  },
  watch: {},
  data() {
    return {
      systemid: null,
      cameraIds: null,
      systemUsername: null,
      systemPassword: null,
      serverUrl: null,
      cameraUrls: [],
    };
  },
  methods: {
    async getNonce() {
      return new Promise((resolve) => {
        var xhr = new XMLHttpRequest();
        xhr.withCredentials = false;

        xhr.addEventListener("readystatechange", function () {
          if (this.readyState === 4) {
            resolve(JSON.parse(this.responseText));
          }
        });

        xhr.open("GET", this.serverUrl + "/api/getNonce");

        xhr.send();
      });
    },
    async getAuth() {
      const self = this;
      return new Promise(async (resolve) => {
        let response = await self.getNonce();
        var realm = response.reply.realm;
        var nonce = response.reply.nonce;
        var digest = window.md5(this.systemUsername + ":" + realm + ":" + this.systemPassword);
        var partial_ha2 = window.md5("GET" + ":");
        var simplified_ha2 = window.md5(
          digest + ":" + nonce + ":" + partial_ha2
        );
        var authKey = btoa(this.systemUsername + ":" + nonce + ":" + simplified_ha2);
        resolve(authKey);
      });
    },
    async renderVideo() {
      let demoAuth = await this.getAuth();
      let urls = [];
      for (let camIndex = 0; camIndex < this.cameraIds.length; camIndex++) {
        urls.push(
          this.serverUrl +
            "/hls/" +
            this.cameraIds[camIndex] +
            ".m3u8?pos="+(new Date().getTime())+"&auth=" +
            demoAuth
        );
      }
      this.$data.cameraUrls = urls;
    },
  },
  computed: {},
  beforeDestroy() {
    /*this.$eventBus.emit("ws-send", {
      action: "UNSUBSCRIBE",
      data: "whatsapp",
    });*/
    //this.$eventBus.off(`ws-whatsapp`);
    //this.$eventBus.off(`switch-service-selected`);
    //this.$eventBus.off(`cache-crm:services`);
  },
  mounted() {
    let self = this;
    ApiService.getWithCache(
      self,
      "crm",
      `${JWTService.getActiveCrmID()}/services`,
      "crm:services"
      //bumpacache
    )
      .then((data) => {
        //console.log(data)
        for (let service of data) {
          if (`${service.id}` === self.$route.params.serviceId) {
            for (let attr of service.attributes) {
              if (attr.key === "pppoeUsername")
                self.$data.systemUsername = attr.value;
              if (attr.key === "pppoePassword")
                self.$data.systemPassword = attr.value;
              if (attr.key === "nxSystemid") {
                self.$data.serverUrl =
                  "https://" + attr.value + ".relay.vmsproxy.com";
                self.$data.systemid = attr.value;
              }
              if (attr.key === "nxCameraids")
                self.$data.cameraIds = attr.value.split(",");
            }
            self.renderVideo();
            //console.log(service);
            return;
          }
        }
        //console.log('END'+self.$route.params.serviceId)
      })
      .catch((response) => {
        self.$log.error(response);
      });
    //self.$eventBus.on("page-focus", (x) => self.pageFocusChanged(x));
    /*self.$eventBus.on("ws-whatsapp", (ev) => {
      if (ev.action === "ON_CHAT") {
        let found = false;
        for (let i = 0; i < self.chats.length; i++) {
          if (self.chats[i].id._serialized === ev.data.id._serialized) {
            found = true;
            self.$data.chats[i] = ev.data;
            break;
          }
        }
        if (!found) {
          self.$data.chats.push(ev.data);
        }
        self.$data.chats = self.$data.chats.sort((a, b) => {
          if (a.timestamp < b.timestamp) return 1;
          if (a.timestamp > b.timestamp) return -1;
          return 0;
        });
        if (self.filterSearch === "") self.scrollToTopOfChatsList();
        //self.$log.info(ev.data);
      }
      if (
        ev.action === "ON_MESSAGE" &&
        ev.data.type === "chat" &&
        ev.data.fromMe !== true &&
        !ev.data._isMuted
      ) {
        self.$bvToast.toast(ev.data.body, {
          title: `${ev.data.from.split("@")[0]} sent you a message....`,
          variant: "info",
          solid: true,
        });
      }
      if (ev.action === "ON_MESSAGE" || ev.action === "ON_ACK") {
        if (ev.data.id.remote === self.selectedChatId) {
          let found = false;
          for (let i = 0; i < self.messages.length; i++) {
            if (
              self.messages[i].id._serialized === ev.data.id._serialized &&
              self.messages[i].timestamp === ev.data.timestamp
            ) {
              found = true;
              for (let key of Object.keys(ev.data))
                self.$data.messages[i][key] = ev.data[key];
              break;
            }
          }
          if (!found) {
            self.$data.messages.push(ev.data);
            self.scrollToBottomOfChat();
          }
          if (ev.data.id.fromMe !== true && self.pageFocused)
            TLib.sendAck(self.$route.params.serviceId, {
              action: "CHAT_ACK",
              data: ev.data.id.remote,
            });
        }
        //console.log(ev.data);
      }
      if (ev.action === "ON_SYNC") {
        self.$data.waState.syncing = ev.data.running;
        self.$data.waState.syncingMsg = ev.data.stage;
      }
      if (ev.action === "NO_AUTH") {
        self.$data.waState.connected = false;
      }
      if (ev.action === "QR_CODE") {
        self.$data.waState.qrCode = ev.data;
      }
      //console.log(ev);
    });*/
    /*self.$eventBus.emit("ws-send", {
      action: "SUBSCRIBE",
      data: "whatsapp",
    });*/
    /*self.$data.waState.timer = setTimeout(() => {
      if (self.waState.connected) {
        self.$eventBus.emit("ws-send", {
          action: "ws-whatsapp",
          data: {
            action: "GET_CHATS",
          },
        });
        window.clearTimeout(self.waState.timer);
        return (self.$data.waState.timer = null);
      }
      console.log(`WA_STATE ${self.waState.connected}`);
      self.$eventBus.emit("ws-send", {
        action: "ws-whatsapp",
        data: {
          action: "GET_STATE",
        },
      });
    }, 15000);*/
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Security" },
      { title: "CCTV" },
    ]);
    //self.getState();
    /*
    try {
      let selectedPage = Number.parseInt(this.$route.query.page || "1");
      this.changePage(this.$route.params.serviceId, selectedPage);
    } catch (xx) {
      this.changePage(this.$route.params.serviceId, 1);
    }

    self.$eventBus.on(`switch-service-selected`, (x) => {
      self.$data.totalCount = null;
      self.changePage(x, 1);
    });*/
    //self.$eventBus.on(`cache-crm:services`, () => self.resync(false));
    //self.resync();
  },
};
</script>
<style scoped lang="scss">
.liveFeedContainer {
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}
</style>
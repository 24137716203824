const ID_TOKEN_KEY = "id_token";
const ID_USER_KEY = "id_user";
const ID_IDENT_KEY = "id_key";

export const getIdentKey = () => {
  return window.sessionStorage.getItem(ID_IDENT_KEY);
};

export const saveIdentKey = key => {
  window.sessionStorage.setItem(ID_IDENT_KEY, key);
};

export const getToken = () => {
  return window.sessionStorage.getItem(ID_TOKEN_KEY);
};

export const saveToken = token => {
  window.sessionStorage.setItem(ID_TOKEN_KEY, token);
};

export const destroyToken = () => {
  window.sessionStorage.removeItem(ID_TOKEN_KEY);
  window.sessionStorage.removeItem("client");
};

export const getUser = () => {
  let usr = window.sessionStorage.getItem(ID_USER_KEY);
  return usr ? JSON.parse(usr) : usr;
};

export const getActiveCrmID = () => {
  return '0';
};

export const saveUser = user => {
  window.sessionStorage.setItem(ID_USER_KEY, JSON.stringify(user));
};

export const destroyUser = () => {
  window.sessionStorage.removeItem(ID_USER_KEY);
};

export default {
  getIdentKey,
  saveIdentKey,
  getToken,
  saveToken,
  destroyToken,
  getUser,
  saveUser,
  destroyUser,
  getActiveCrmID
};

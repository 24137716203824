<template>
  <v-card
    class="mx-auto my-12"
    width="400"
    height="300"
    style="margin: 20px;">
    <video :id="tId" controls style="width: 100%; height: 100%"></video>
  </v-card>
</template>

<script>
import uuid from "uuid";
import HLSJS from "hls.js";
export default {
  props: ["cameraUrl"],
  data() {
    return {
      tId: "naaaaa",
    };
  },
  created() {
    this.$data.tId = `security-cctv-live-${uuid.v4()}`;
  },
  mounted() {
    if (HLSJS.isSupported()) {
      let video = document.getElementById(this.tId);
      let hls = new HLSJS();
      hls.loadSource(this.cameraUrl);
      hls.attachMedia(video);
      hls.on(HLSJS.Events.MANIFEST_PARSED, function () {
        //console.log("PLAY");
        video.setAttribute("playing", "yes");
        video.play();
      });
    }
  },
};
</script>